.btn-title{
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 30px;
}

.navbar-brand img
{
  max-height: 40px;
  margin: 10px 0px
}

h2, h3, h4, p
{
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  text-wrap: balance
}

.showcase {
  background-color: #1BADEE;
  color: aliceblue;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
}

.testimonials
{
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
}

strong
{
  font-weight: bold;
}

.call-to-action p{
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  font-size: x-large;
}

.footer img {
  max-height: 40px;
  margin-bottom: 25px;
}

.footer p {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
}